import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { i18n } from "../lib/i18n";
import "../styles/globals.css";
import { urqlClient } from "../lib/urql-client";
import { Provider } from "urql";
import { UserProvider } from "@auth0/nextjs-auth0";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MyApp({ Component, pageProps }) {
  const { locale } = useRouter();

  useEffect(() => {
    i18n.changeLanguage(locale!);
  }, [locale]);

  return (
    <I18nextProvider i18n={i18n}>
      <Provider value={urqlClient}>
        <UserProvider>
          <Head>
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="69d8bcd5-19e1-4831-86f0-1a699c406a45";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
              }}
            />
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-158775003-3"></script>
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'UA-158775003-3');
                `,
              }}
            />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta charSet="UTF-8" />
            <title>Tierversand vom Profi</title>
            <meta property="og:title" content="Tierversand vom Profi" key="title" />
          </Head>
          <div className="h-full text-gray-700">
            <Component {...pageProps} />
          </div>
        </UserProvider>
      </Provider>
      <ToastContainer />
    </I18nextProvider>
  );
}

export default MyApp;
