import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    "de-DE": {
        common: require('../public/locales/de-DE/common.json'),
        booking: require('../public/locales/de-DE/booking.json'),
    },
    "en-US": {
        common: require('../public/locales/en-US/common.json'),
    },
} as any;

type Config = string | boolean | null | string[];

export const i18nConfig: Record<string, Record<string, Config> | Config> = {
    resources,
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
        escapeValue: false,
    },
    lng: 'de-DE',
};

//TODO activate for production
if (!i18n.isInitialized) {
    i18n.use(initReactI18next).init({
        ...i18nConfig,
    });
}

export { i18n };