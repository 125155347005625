import { createClient } from 'urql';
import cookie from 'cookie';

export const urqlClient = createClient({
  // TODO: Detect the environment and use the right link
  url: process.env.NEXT_PUBLIC_API_URI,
  fetchOptions: () => {
    return {
      credentials: 'same-origin'
    };
  },
});
